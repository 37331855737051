<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Candidate List</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Candidate List</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="profile-area profile-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy1.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Trainer and Assessor</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="../../../../assets/dummy/dummy2.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jac Jacson</h3>
                        <span>CEO</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="../../../../assets/dummy/dummy3.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Tom Potter</h3>
                        <span>Compliance Manager</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="../../../../assets/dummy/dummy4.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Shane Mac</h3>
                        <span>RTO Manager</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy5.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Liam Noah</h3>
                        <span>Training Coordinator</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="../../../../assets/dummy/dummy6.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>William James</h3>
                        <span>Student Support Officer</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="../../../../assets/dummy/dummy7.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Lucas Mason</h3>
                        <span>Wall and Floor Tiler</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="../../../../assets/dummy/dummy8.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Daniel Henry</h3>
                        <span>Roof Tiler</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy9.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Automotive Technician</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy10.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Motor Mechanic</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy11.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>RTO Manager</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="profile-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="../../../../assets/dummy/dummy12.jpg" class="profile_images" alt="Profile">

                    <div class="profile-inner">
                        <h3>Jerry Hudson</h3>
                        <span>Student Support Officer</span>
                        <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                        <div class="profile-heart">
                            <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



