<div class="navbar-area fixed-top">
    
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="../../../../assets/img/magnus-logo-jpg.jpg" alt="Logo"></a>
    </div>
    
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="../../../../assets/img/magnus-logo-jpg.jpg" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/">Home <i class="icofont-simple-down"></i></a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
                            </ul> -->
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Jobs <i class="icofont-simple-down"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/candidate/job-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Job List</a></li>

                                <li class="nav-item"><a routerLink="/favourite-job" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Favourite Jobs</a></li>

                                <li class="nav-item"><a routerLink="/job-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Job Details</a></li>

                                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Post A Job</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates <i class="icofont-simple-down"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/candidate-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Candidate List</a></li>

                                <li class="nav-item"><a routerLink="/candidate-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Candidate Details</a></li>

                                <li class="nav-item"><a routerLink="/single-resume" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Single Resume</a></li>

                                <li class="nav-item"><a routerLink="/submit-resume" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Submit Resume</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Candidate dashboard</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class="icofont-simple-down"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/company-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Company List</a></li>

                                <li class="nav-item"><a routerLink="/company-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Company Details</a></li>

                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login Page</a></li>

                                <li class="nav-item"><a routerLink="/create-account" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Create Account Page</a></li>

                                <li class="nav-item"><a routerLink="/profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Profile</a></li>

                                <li class="nav-item"><a routerLink="/single-profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Single Profile</a></li>

                                <li class="nav-item"><a routerLink="/404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-and-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms and Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blogs <i class="icofont-simple-down"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    </ul>

                    <div class="common-btn">
                        <ng-container *ngIf="!user"  >
                            <a
                                class="login-btn"
                                routerLink="/management-module/login"
                                ><i class="icofont-plus-square"></i> Login</a
                            >
                            <a class="sign-up-btn">
                            <ul class="navbar-nav" >
                            <li class="nav-item" style="border: 1px solid rgb(56, 167, 69);background: linear-gradient(90deg, rgb(56, 167, 69) 0%, rgb(76, 206, 91) 63%)">
                                <a href="javascript:void(0)" style="text-decoration: none; color: inherit;"> <i class="icofont-user-alt-4"></i>Sign Up <i class="icofont-simple-down"></i></a>
    
                                <ul class="dropdown-menu">
                                    <li><a routerLink="/management-module/employer-sign-up"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" style="width: 240px;">Sign up as Employer</a></li>
    
                                    <li ><a routerLink="/management-module/candidate-sign-up"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" style="width: 240px;">Sign up as Candidate</a></li>
                                </ul>
                            </li>
                        </ul>
                    </a>

                    
                            <!-- <a
                                class="sign-up-btn"
                                routerLink="/management-module/candidate-sign-up"
                                ><i class="icofont-user-alt-4"></i> Sign Up</a
                            > -->
                           
                            
                        </ng-container>

                        <a
                            *ngIf="user"
                            class="login-btn"
                            (click)="handleLogout()"
                            ><i class="icofont-plus-square"></i> Logout</a
                        >
                        
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>