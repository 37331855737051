import { Injectable, inject } from "@angular/core";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Store } from "@ngxs/store";
import {
    Observable,
    map,
} from "rxjs";
import {
    actionsExecuting,
    ActionsExecuting,
} from "@ngxs-labs/actions-executing";

import { environment } from "src/environments/environment";
import { HttpClient} from "@angular/common/http";
import { AuthState } from "./auth.state";
import { AuthActions } from "./auth.actions";

@Injectable({
    providedIn: "root",
})
export class AuthService {
   
    constructor(
        private _Store: Store,
        private readonly HttpClient: HttpClient,
    ) {}

    userO: Observable<any> = this._Store.select(AuthState.getUserData);

    // @Select((state: { User: AuthStateModel }) => state.User.user)
    // userO!: Observable<any>;


    @Dispatch()
    setNullUser() {
        return new AuthActions.Register(null);
    }

    
    //register candidate and employee
    @Dispatch()
    registerUser(payload: any) {
        const response = this.HttpClient.post(
            `${environment.baseUrl}/auth/signup`,
            payload
        );
        return response.pipe(map((data) => new AuthActions.Register(data)));
    }

    //login candidate and employee
    @Dispatch()
    login(payload: any){
        return this.HttpClient.post(`${environment.baseUrl}/auth/signin`, payload).pipe(
            map((data: any) => new AuthActions.Login(data)),
        );
    }

    //update candidate
    @Dispatch()
    updateCandidateProfileDetails(payload: any , id:any) {
        const response = this.HttpClient.patch(
            `${environment.baseUrl}/candidate/update-specific-candidate/${id}`,
            payload
        );
        return response.pipe(map((data) => new AuthActions.UpdateUser(data)));
    
    }

    //update employee
    @Dispatch()
    updateEmployeeProfileDetails(payload: any , id:any) {
        const response = this.HttpClient.patch(
            `${environment.baseUrl}/employer/update-specific-employer/${id}`,
            payload
        );
        return response.pipe(map((data) => new AuthActions.UpdateUser(data)));
    
    }

    @Dispatch()
    updateUser(payload: any){
        return new AuthActions.UpdateUser(payload)
    }

    //forgot password
    forgotPassword(payload: any) {
        return this.HttpClient.post(
            `${environment.baseUrl}/auth/forget-password`,
            payload
        );
    }

    //reset password
    resetPassword(payload: any) {
        return this.HttpClient.post(
            `${environment.baseUrl}/auth/reset-password`,
            payload
        );
    }

    


}
