import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
 private jobId : string ;

  constructor() { }


    //save user token
    userToken(payLoad: any) {
        localStorage.setItem("token", payLoad);
    }

    //get user token
    getUserToken(): string | null {
        return localStorage.getItem("token");
    }

    //save user login data
    setUserData(payload:any){
        const jsonData = JSON.stringify(payload);
        localStorage.setItem('user-data', jsonData);
    }
    //get user login data
    getUserData(){
        return  JSON.parse(localStorage.getItem("user-data")) || null
    }
 
    //save job id 
    saveJobId(id:any){
        JSON.stringify(localStorage.setItem('questions-id',id))   
      }
    
    //get job id
    getJobId(): string | null{
        return localStorage.getItem("questions-id");
        // return JSON.parse(localStorage.getItem('questions-id')) || null;  
    }  

    // saveJobId(id:any){
    //    return this.jobId = id
    // }
    
    // getJobId(){
    //     return this.jobId;
    // }
}
